import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import {
    faAddressCard as faAddressCardDuo,
    faAnalytics as faAnalyticsDuo,
    faBooks,
    faBoxAlt as faBoxAltDuo,
    faBrowser as faBrowserDuo,
    faBuilding as faBuildingDuo,
    faBullhorn,
    faChartLine as faChartLineDuo,
    faCheckCircle as faCheckCircleDuo,
    faClipboardCheck as faClipboardCheckDuo,
    faClock as faClockDuo,
    faCloud as faCloudDuo,
    faCloudUpload as faCloudUploadDuo,
    faCogs as faCogsDuo,
    faDoNotEnter as faDoNotEnterDuo,
    faDoorOpen as faDoorOpenDuo,
    faExclamationCircle as faExclamationCircleDuo,
    faExclamationTriangle as faExclamationTriangleDuo,
    faFileExcel as faFileExcelDuo,
    faFileSearch as faFileSearchDuo,
    faFilm,
    faFolderOpen as faFolderOpenDuo,
    faGlobeEurope as faGlobeEuropeDuo,
    faHandHoldingUsd as faHandHoldingUsdDuo,
    faHistory as faHistoryDuo,
    faIdBadge as faIdBadgeDuo,
    faIndustryAlt as faIndustryAltDuo,
    faLayerGroup as faLayerGroupDuo,
    faListAlt as faListAltDuo,
    faPalletAlt as faPalletAltDuo,
    faPlaneAlt as faPlaneAltDuo,
    faPlug as faPlugDuo,
    faProjectDiagram as faProjectDiagramDuo,
    faQuestionCircle as faQuestionCircleDuo,
    faRocketLaunch as faRocketLaunchDuo,
    faSearchLocation as faSearchLocationDuo,
    faShieldCheck as faShieldCheckDuo,
    faShip as faShipDuo,
    faSlidersVSquare as faSlidersVSquareDuo,
    faSpinner as faSpinnerDuo,
    faStopwatch as faStopwatchDuo,
    faSun as faSunDuo,
    faTasksAlt as faTasksAltDuo,
    faThumbsUp as faThumbsUpDuo,
    faTruck as faTruckDuo,
    faUser as faUserDuo,
    faUserChart,
    faUserHeadset as faUserHeadsetDuo,
    faUsers as faUsersDuo,
    faUsersClass as faUsersClassDuo,
} from '@fortawesome/pro-duotone-svg-icons';

import {
    faAnchor as faAnchorSolid,
    faArrowFromRight as faArrowFromRightSolid,
    faAward as faAwardSolid,
    faBan as faBanSolid,
    faBell as faBellSolid,
    faBooks as faBooksSolid,
    faBoxAlt as faBoxAltSolid,
    faBullhorn as faBullhornSolid,
    faCalendar as faCalendarSolid,
    faCaretDown as faCaretDownSolid,
    faCaretUp as faCaretUpSolid,
    faChartLine as faChartLineSolid,
    faChartNetwork as faChartNetworkSolid,
    faCheck as faCheckSolid,
    faCheckCircle as faCheckCircleSolid,
    faClock as faClockSolid,
    faCog as faCogSolid,
    faComments as faCommentsSolid,
    faContainerStorage as faContainerStorageSolid,
    faConveyorBelt as faConveyorBeltSolid,
    faDollarSign as faDollarSignSolid,
    faDoorOpen as faDoorOpenSolid,
    faEllipsisV as faEllipsisVSolid,
    faEmptySet as faEmptySetSolid,
    faEnvelope as faEnvelopeSolid,
    faExclamationCircle as faExclamationCircleSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
    faFile as faFileSolid,
    faFileInvoice as faFileInvoiceSolid,
    faFileInvoiceDollar as faFileInvoiceDollarSolid,
    faFire as faFireSolid,
    faFlask as faFlaskSolid,
    faForklift as faForkliftSolid,
    faHandHoldingUsd as faHandHoldingUsdSolid,
    faHandshakeAlt as faHandshakeAltSolid,
    faHandsUsd as faHandsUsdSolid,
    faHistory as faHistorySolid,
    faHome as faHomeSolid,
    faHouse as faHouseSolid,
    faInfoCircle as faInfoCircleSolid,
    faList as faListSolid,
    faLongArrowRight as faLongArrowRightSolid,
    faMailbox as faMailboxSolid,
    faMapPin as faMapPinSolid,
    faPalletAlt as faPalletAltSolid,
    faPlane as faPlaneSolid,
    faPlaneArrival as faPlaneArrivalSolid,
    faPlaneDeparture as faPlaneDepartureSolid,
    faPlay as faPlaySolid,
    faQuestion,
    faRadar as faRadarSolid,
    faRetweet as faRetweetSolid,
    faRoute as faRouteSolid,
    faSatelliteDish as faSatelliteDishSolid,
    faShieldCheck as faShieldCheckSolid,
    faShip as faShipSolid,
    faSnowflake as faSnowflakeSolid,
    faSortAmountDown as faSortAmountDownSolid,
    faSortAmountUp as faSortAmountUpSolid,
    faSpinner,
    faStar as faStarSolid,
    faStopwatch as faStopwatchSolid,
    faSun as faSunSolid,
    faTerminal as faTerminalSolid,
    faTimes as faTimesSolid,
    faTruck as faTruckSolid,
    faTruckLoading as faTruckLoadingSolid,
    faUserClock as faUserClockSolid,
    faUserGraduate as faUserGraduateSolid,
    faUserMinus,
    faUserPlus,
    faWarehouse as faWarehouseSolid,
    faPlusCircle as faPlusCircleSolid
} from '@fortawesome/pro-solid-svg-icons';

import {
    faBoxAlt as faBoxAltLight,
    faDoorOpen as faDoorOpenLight,
    faFileUpload as faFileUploadLight,
    faHandHoldingUsd as faHandHoldingUsdLight,
    faSpinner as faSpinnerLight,
} from '@fortawesome/pro-light-svg-icons';
import {
    faAddressBook as faAddressBookRegular,
    faAlarmClock as faAlarmClockRegular,
    faAlarmExclamation as faAlarmExclamationRegular,
    faAnalytics as faAnalyticsRegular,
    faAnchor as faAnchorRegular,
    faAngleDown as faAngleDownRegular,
    faAngleLeft as faAngleLeftRegular,
    faAngleRight as faAngleRightRegular,
    faAngleUp as faAngleUpRegular,
    faArchive as faArchiveRegular,
    faArrowAltDown as faArrowAltDownRegular,
    faArrowAltLeft as faArrowAltLeftRegular,
    faArrowAltUp as faArrowAltUpRegular,
    faArrowFromBottom as faArrowFromBottomRegular,
    faArrowFromLeft as faArrowFromLeftRegular,
    faArrowFromRight as faArrowFromRightRegular,
    faArrowLeft as faArrowLeftRegular,
    faArrowRight as faArrowRightRegular,
    faArrowToBottom as faArrowToBottomRegular,
    faAsterisk as faAsteriskRegular,
    faAward as faAwardRegular,
    faBan as faBanRegular,
    faBarcodeAlt as faBarcodeAltRegular,
    faBars as faBarsRegular,
    faBell as faBellRegular,
    faBook as faBookRegular,
    faBox as faBoxRegular,
    faBoxAlt as faBoxAltRegular,
    faBoxCheck as faBoxCheckRegular,
    faBoxes as faBoxesRegular,
    faBriefcase as faBriefcaseRegular,
    faBroom as faBroomRegular,
    faBug as faBugRegular,
    faBuilding as faBuildingRegular,
    faCalendar as faCalendarRegular,
    faCalendarAlt as faCalendarAltRegular,
    faCalendarEdit as faCalendarEditRegular,
    faCamera as faCameraRegular,
    faCartPlus as faCartPlusRegular,
    faChartBar as faChartBarRegular,
    faChartLine as faChartLineRegular,
    faChartNetwork as faChartNetworkRegular,
    faCheck as faCheckRegular,
    faCheckCircle as faCheckCircleRegular,
    faCheckSquare as faCheckSquareRegular,
    faChevronDoubleUp as faChevronDoubleUpRegular,
    faCircle as faCircleRegular,
    faClipboardCheck as faClipboardCheckRegular,
    faClipboardList as faClipboardListRegular,
    faClock as faClockRegular,
    faClone as faCloneRegular,
    faCloud as faCloudRegular,
    faCloudDownload as faCloudDownloadRegular,
    faCloudUpload as faCloudUploadRegular,
    faCodeBranch as faCodeBranchRegular,
    faCog as faCogRegular,
    faComment as faCommentRegular,
    faCompass as faCompassRegular,
    faCompress as faCompressRegular,
    faContainerStorage as faContainerStorageRegular,
    faConveyorBelt as faConveyorBeltRegular,
    faCopy as faCopyRegular,
    faDollarSign as faDollarSignRegular,
    faDolly as faDollyRegular,
    faDollyFlatbed as faDollyFlatbedRegular,
    faDoNotEnter as faDoNotEnterRegular,
    faDotCircle as faDotCircleRegular,
    faEdit as faEditRegular,
    faEllipsisH as faEllipsisHRegular,
    faEllipsisV as faEllipsisVRegular,
    faEngineWarning as faEngineWarningRegular,
    faEnvelope as faEnvelopeRegular,
    faEnvelopeOpen as faEnvelopeOpenRegular,
    faExchange as faExchangeRegular,
    faExclamationCircle as faExclamationCircleRegular,
    faExclamationTriangle as faExclamationTriangleRegular,
    faExpand as faExpandRegular,
    faExternalLink as faExternalLinkRegular,
    faEye as faEyeRegular,
    faEyeSlash as faEyeSlashRegular,
    faFile as faFileRegular,
    faFileAlt as faFileAltRegular,
    faFileArchive as faFileArchiveRegular,
    faFileContract as faFileContractRegular,
    faFileCsv as faFileCsvRegular,
    faFileDownload as faFileDownloadRegular,
    faFileExcel as faFileExcelRegular,
    faFileInvoice as faFileInvoiceRegular,
    faFileInvoiceDollar as faFileInvoiceDollarRegular,
    faFilePdf as faFilePdfRegular,
    faFileSignature as faFileSignatureRegular,
    faFileSpreadsheet as faFileSpreadsheetRegular,
    faFileUpload as faFileUploadRegular,
    faFileWord as faFileWordRegular,
    faFilter as faFilterRegular,
    faFlag as faFlagRegular,
    faFolderOpen as faFolderOpenRegular,
    faForklift as faForkliftRegular,
    faFunnelDollar as faFunnelDollarRegular,
    faGlobe as faGlobeRegular,
    faGlobeEurope as faGlobeEuropeRegular,
    faGreaterThanEqual as faGreaterThanEqualRegular,
    faHandHoldingBox as faHandHoldingBoxRegular,
    faHandHoldingUsd as faHandHoldingUsdRegular,
    faHandReceiving as faHandReceivingRegular,
    faHandsUsd as faHandsUsdRegular,
    faHashtag as faHashtagRegular,
    faHistory as faHistoryRegular,
    faHome as faHomeRegular,
    faHouse as faHouseRegular,
    faIdCard as faIdCardRegular,
    faIdCardAlt as faIdCardAltRegular,
    faInbox as faInboxRegular,
    faInboxIn as faInboxInRegular,
    faInboxOut as faInboxOutRegular,
    faIndustryAlt as faIndustryAltRegular,
    faInfoCircle as faInfoCircleRegular,
    faInfoSquare as faInfoSquareRegular,
    faIntersection as faIntersectionRegular,
    faInventory as faInventoryRegular,
    faKey as faKeyRegular,
    faLanguage as faLanguageRegular,
    faLayerGroup as faLayerGroupRegular,
    faLink as faLinkRegular,
    faList as faListRegular,
    faListAlt as faListAltRegular,
    faListUl as faListUlRegular,
    faLock as faLockRegular,
    faLongArrowRight as faLongArrowRightRegular,
    faMailbox as faMailboxRegular,
    faMap as faMapRegular,
    faMapMarked as faMapMarkedRegular,
    faMapPin as faMapPinRegular,
    faMedal as faMedalRegular,
    faMinus as faMinusRegular,
    faPallet as faPalletRegular,
    faPalletAlt as faPalletAltRegular,
    faPaperclip as faPaperclipRegular,
    faPaperPlane as faPaperPlaneRegular,
    faPauseCircle as faPauseCircleRegular,
    faPen as faPenRegular,
    faPencil as faPencilRegular,
    faPlane as faPlaneRegular,
    faPlaneAlt as faPlaneAltRegular,
    faPlaneArrival as faPlaneArrivalRegular,
    faPlaneDeparture as faPlaneDepartureRegular,
    faPlay as faPlayRegular,
    faPlayCircle as faPlayCircleRegular,
    faPlug as faPlugRegular,
    faPlus as faPlusRegular,
    faPowerOff as faPowerOffRegular,
    faProjectDiagram as faProjectDiagramRegular,
    faQuestion as faQuestionRegular,
    faQuestionCircle as faQuestionCircleRegular,
    faQuoteLeft as faQuoteLeftRegular,
    faQuoteRight as faQuoteRightRegular,
    faRadar as faRadarRegular,
    faRedo as faRedoRegular,
    faReply as faReplyRegular,
    faRetweet as faRetweetRegular,
    faRocket as faRocketRegular,
    faRocketLaunch as faRocketLaunchRegular,
    faRoute as faRouteRegular,
    faRss as faRssRegular,
    faRuler as faRulerRegular,
    faSave as faSaveRegular,
    faScanner as faScannerRegular,
    faSearch as faSearchRegular,
    faShare as faShareRegular,
    faShieldAlt as faShieldAltRegular,
    faShip as faShipRegular,
    faShippingFast as faShippingFastRegular,
    faShippingTimed as faShippingTimedRegular,
    faShoppingCart as faShoppingCartRegular,
    faSignIn as faSignInRegular,
    faSignOut as faSignOutRegular,
    faSitemap,
    faSlash as faSlashRegular,
    faSlidersVSquare as faSlidersVSquareRegular,
    faSortAmountDown as faSortAmountDownRegular,
    faSortAmountUp as faSortAmountUpRegular,
    faSpinner as faSpinnerRegular,
    faStar as faStarRegular,
    faStarExclamation as faStarExclamationRegular,
    faStickyNote as faStickyNoteRegular,
    faStopwatch as faStopwatchRegular,
    faStream as faStreamRegular,
    faSun as faSunRegular,
    faSwimmer as faSwimmerRegular,
    faSync as faSyncRegular,
    faTable as faTableRegular,
    faTachometerAltFast as faTachometerAltFastRegular,
    faTasksAlt as faTasksAltRegular,
    faTerminal as faTerminalRegular,
    faTimes as faTimesRegular,
    faTimesCircle as faTimesCircleRegular,
    faTools as faToolsRegular,
    faTrain as faTrainRegular,
    faTrash as faTrashRegular,
    faTruck as faTruckRegular,
    faTruckLoading as faTruckLoadingRegular,
    faTruckMoving as faTruckMovingRegular,
    faTruckRamp as faTruckRampRegular,
    faUmbrellaBeach as faUmbrellaBeachRegular,
    faUndoAlt as faUndoAltRegular,
    faUnlink as faUnlinkRegular,
    faUsdCircle as faUsdCircleRegular,
    faUser as faUserRegular,
    faUserHeadset as faUserHeadsetRegular,
    faUserNinja as faUserNinjaRegular,
    faUserPlus as faUserPlusRegular,
    faUsers as faUsersRegular,
    faUserSecret as faUserSecretRegular,
    faUserTimes as faUserTimesRegular,
    faWarehouse as faWarehouseRegular,
} from '@fortawesome/pro-regular-svg-icons';

export default function loadIcons(library: FaIconLibrary) {
    // regular

    library.addIcons(
        faUserNinjaRegular,
        faInfoSquareRegular,
        faSortAmountUpRegular,
        faArrowFromBottomRegular,
        faArrowToBottomRegular,
        faAddressBookRegular,
        faAlarmClockRegular,
        faAlarmExclamationRegular,
        faAnalyticsRegular,
        faAnchorRegular,
        faAngleDownRegular,
        faAngleLeftRegular,
        faAngleRightRegular,
        faAngleUpRegular,
        faArchiveRegular,
        faArrowAltDownRegular,
        faArrowAltLeftRegular,
        faArrowAltUpRegular,
        faArrowFromLeftRegular,
        faArrowFromRightRegular,
        faArrowLeftRegular,
        faArrowRightRegular,
        faAsteriskRegular,
        faAwardRegular,
        faBanRegular,
        faBarcodeAltRegular,
        faBarsRegular,
        faBellRegular,
        faBookRegular,
        faBoxRegular,
        faBoxAltRegular,
        faBoxCheckRegular,
        faBoxesRegular,
        faBriefcaseRegular,
        faBroomRegular,
        faBugRegular,
        faBuildingRegular,
        faCalendarRegular,
        faCalendarAltRegular,
        faCalendarEditRegular,
        faCameraRegular,
        faCartPlusRegular,
        faChartBarRegular,
        faChartLineRegular,
        faChartNetworkRegular,
        faCheckRegular,
        faCheckCircleRegular,
        faCheckSquareRegular,
        faChevronDoubleUpRegular,
        faCircleRegular,
        faClipboardCheckRegular,
        faClipboardListRegular,
        faClockRegular,
        faCloneRegular,
        faCloudRegular,
        faCloudDownloadRegular,
        faCloudUploadRegular,
        faCodeBranchRegular,
        faCogRegular,
        faCommentRegular,
        faCompassRegular,
        faCompressRegular,
        faContainerStorageRegular,
        faConveyorBeltRegular,
        faCopyRegular,
        faDollarSignRegular,
        faDollyRegular,
        faDollyFlatbedRegular,
        faDotCircleRegular,
        faEditRegular,
        faEllipsisHRegular,
        faEllipsisVRegular,
        faEnvelopeRegular,
        faEnvelopeOpenRegular,
        faExchangeRegular,
        faExclamationCircleRegular,
        faExclamationTriangleRegular,
        faExpandRegular,
        faExternalLinkRegular,
        faEyeRegular,
        faEyeSlashRegular,
        faFileRegular,
        faFileAltRegular,
        faFileArchiveRegular,
        faFileContractRegular,
        faFileCsvRegular,
        faFileDownloadRegular,
        faFileExcelRegular,
        faFileInvoiceRegular,
        faFileInvoiceDollarRegular,
        faFilePdfRegular,
        faFileSignatureRegular,
        faFileSpreadsheetRegular,
        faFileUploadRegular,
        faFileWordRegular,
        faFilterRegular,
        faFlagRegular,
        faFolderOpenRegular,
        faForkliftRegular,
        faFunnelDollarRegular,
        faGlobeRegular,
        faGlobeEuropeRegular,
        faGreaterThanEqualRegular,
        faHandHoldingBoxRegular,
        faHandHoldingUsdRegular,
        faHandReceivingRegular,
        faHandsUsdRegular,
        faHashtagRegular,
        faHistoryRegular,
        faHomeRegular,
        faHouseRegular,
        faIdCardRegular,
        faIdCardAltRegular,
        faInboxRegular,
        faInboxInRegular,
        faInboxOutRegular,
        faIndustryAltRegular,
        faInfoCircleRegular,
        faIntersectionRegular,
        faInventoryRegular,
        faKeyRegular,
        faLanguageRegular,
        faLayerGroupRegular,
        faLinkRegular,
        faListRegular,
        faListAltRegular,
        faListUlRegular,
        faLockRegular,
        faLongArrowRightRegular,
        faMailboxRegular,
        faMapRegular,
        faMapMarkedRegular,
        faMapPinRegular,
        faMedalRegular,
        faMinusRegular,
        faPalletRegular,
        faPalletAltRegular,
        faPaperPlaneRegular,
        faPaperclipRegular,
        faPauseCircleRegular,
        faPenRegular,
        faPencilRegular,
        faPlaneRegular,
        faPlaneAltRegular,
        faPlaneArrivalRegular,
        faPlaneDepartureRegular,
        faPlayRegular,
        faPlayCircleRegular,
        faPlugRegular,
        faPlusRegular,
        faPowerOffRegular,
        faProjectDiagramRegular,
        faQuestionRegular,
        faQuestionCircleRegular,
        faRedoRegular,
        faReplyRegular,
        faRetweetRegular,
        faRocketRegular,
        faRocketLaunchRegular,
        faRouteRegular,
        faRssRegular,
        faRulerRegular,
        faSaveRegular,
        faScannerRegular,
        faSearchRegular,
        faShareRegular,
        faShieldAltRegular,
        faShipRegular,
        faShippingFastRegular,
        faShippingTimedRegular,
        faShoppingCartRegular,
        faSignInRegular,
        faSignOutRegular,
        faSlashRegular,
        faSlidersVSquareRegular,
        faSortAmountDownRegular,
        faSpinnerRegular,
        faStarRegular,
        faStarExclamationRegular,
        faStickyNoteRegular,
        faStopwatchRegular,
        faStreamRegular,
        faSunRegular,
        faSwimmerRegular,
        faSyncRegular,
        faTableRegular,
        faTachometerAltFastRegular,
        faTasksAltRegular,
        faTimesRegular,
        faTimesCircleRegular,
        faToolsRegular,
        faTrainRegular,
        faTrashRegular,
        faTruckRegular,
        faTruckLoadingRegular,
        faTruckMovingRegular,
        faTruckRampRegular,
        faUmbrellaBeachRegular,
        faUndoAltRegular,
        faUnlinkRegular,
        faUsdCircleRegular,
        faUserRegular,
        faUserHeadsetRegular,
        faUserPlusRegular,
        faUserTimesRegular,
        faUserSecretRegular,
        faUsersRegular,
        faWarehouseRegular,
        faTerminalRegular,
        faDoNotEnterRegular,
        faRadarRegular,
        faEngineWarningRegular,
        faQuoteLeftRegular,
        faQuoteRightRegular,
        faSitemap
    );

    // Solid
    library.addIcons(
        faSortAmountDownSolid,
        faSortAmountUpSolid,
        faCaretUpSolid,
        faCaretDownSolid,
        faBellSolid,
        faUserPlus,
        faUserMinus,
        faExclamationCircleSolid,
        faBanSolid,
        faUserClockSolid,
        faFileSolid,
        faCommentsSolid,
        faRetweetSolid,
        faClockSolid,
        faStopwatchSolid,
        faTruckLoadingSolid,
        faDollarSignSolid,
        faFileInvoiceDollarSolid,
        faDollarSignSolid,
        faChartLineSolid,
        faAwardSolid,
        faConveyorBeltSolid,
        faForkliftSolid,
        faHandshakeAltSolid,
        faRouteSolid,
        faInfoCircleSolid,
        faShipSolid,
        faLongArrowRightSolid,
        faCalendarSolid,
        faListSolid,
        faMailboxSolid,
        faTruckSolid,
        faHistorySolid,
        faPalletAltSolid,
        faContainerStorageSolid,
        faPlaneDepartureSolid,
        faPlaneArrivalSolid,
        faAnchorSolid,
        faMapPinSolid,
        faCheckSolid,
        faTimesSolid,
        faBullhornSolid,
        faHomeSolid,
        faBoxAltSolid,
        faFileInvoiceSolid,
        faHandsUsdSolid,
        faArrowFromRightSolid,
        faPlaySolid,
        faChartNetworkSolid,
        faDoorOpenSolid,
        faTerminalSolid,
        faHandHoldingUsdSolid,
        faPlaneSolid,
        faFireSolid,
        faSnowflakeSolid,
        faHouseSolid,
        faEmptySetSolid,
        faEnvelopeSolid,
        faShieldCheckSolid,
        faCheckCircleSolid,
        faRadarSolid,
        faSatelliteDishSolid,
        faUserGraduateSolid,
        faCogSolid,
        faExclamationTriangleSolid,
        faEllipsisVSolid,
        faFlaskSolid,
        faBooksSolid,
        faSunSolid,
        faStarSolid,
        faWarehouseSolid,
        faPlusCircleSolid,
        faSpinner,
        faQuestion
    );

    // duotone
    library.addIcons(
        faShipDuo,
        faPlaneAltDuo,
        faPalletAltDuo,
        faLayerGroupDuo,
        faProjectDiagramDuo,
        faUsersDuo,
        faShieldCheckDuo,
        faAddressCardDuo,
        faCheckCircleDuo,
        faSpinnerDuo,
        faBrowserDuo,
        faTasksAltDuo,
        faCloudDuo,
        faTruckDuo,
        faIndustryAltDuo,
        faFileExcelDuo,
        faUsersClassDuo,
        faCloudUploadDuo,
        faStopwatchDuo,
        faChartLineDuo,
        faSlidersVSquareDuo,
        faBuildingDuo,
        faHistoryDuo,
        faPlugDuo,
        faUserDuo,
        faAnalyticsDuo,
        faListAltDuo,
        faFolderOpenDuo,
        faFileSearchDuo,
        faHandHoldingUsdDuo,
        faQuestionCircleDuo,
        faSearchLocationDuo,
        faDoNotEnterDuo,
        faExclamationTriangleDuo,
        faThumbsUpDuo,
        faIdBadgeDuo,
        faCogsDuo,
        faGlobeEuropeDuo,
        faClipboardCheckDuo,
        faUserHeadsetDuo,
        faRocketLaunchDuo,
        faClockDuo,
        faDoorOpenDuo,
        faBoxAltDuo,
        faBooks,
        faFilm,
        faBullhorn,
        faUserChart,
        faExclamationCircleDuo,
        faSunDuo
    );

    // light

    library.addIcons(
        faFileUploadLight,
        faHandHoldingUsdLight,
        faBoxAltLight,
        faDoorOpenLight,
        faSpinnerLight
    );

    // brands
    library.addIcons(faGoogle);
}
