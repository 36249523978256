import Pusher from 'pusher-js';

// new hub envir
export const environment = {
    name: 'next',
    url: 'https://hub.next.qwykportals.com',
    badge: 'badge-success',
    sentry: {
        enabled: true,
        dsn: 'https://ea089f67cc2643ed89a8db0135fbc0ef@sentry.io/4825874',
        traceOrigins: [
            'https://hub.next.qwykportals.com',
            'https://api.next.qwykportals.com',
        ],
        tracesSampleRate: 0.2,
    },
    debug: false,
    production: true,
    backendServer: 'https://api.next.qwykportals.com',
    schedulesBackend: 'https://api.next.qwykschedules.com',
    portalSitesMask: 'https://{0}.next.qwykportals.com',
    feedbackSite: 'https://magaya.ideas.aha.io',
    auth0: {
        domain: 'magaya-prod.us.auth0.com',
        clientId: 'uRiAi2o1plD9O5M87pfauPp6hWpe07t7',
        authorizationParams: {
            redirect_uri: 'https://hub.next.qwykportals.com/callback',
            audience: 'https://apps.qwyk.io',
        },
    },
    supportEmail: 'rmsupport@magaya.com',
    utils: {
        Algolia: {
            applicationId: '3G8S1ON0FI',
            adminApiKey: 'c397d91713a36b2e696eb4454f22ec40',
        },
        Echo: {
            userModel: 'App.Models.Hub.Auth.User',
            notificationNamespace: 'App\\Notifications',
            options: {
                broadcaster: 'pusher',
                key: 'cfdce8473b7158ad4e17',
                cluster: 'eu',
                forceTLS: true,

                client: new Pusher('cfdce8473b7158ad4e17', {
                    cluster: 'eu',
                    enabledTransports: ['ws', 'wss'],
                    authEndpoint:
                        'https://api.next.qwykportals.com/api/hub/broadcasting/auth',
                }),
            },
        },
        Veem: {
            environment: 'sandbox',
            clientId: 'MagayaDFP-VnqdrKYa9Y',
        },
        Amplitude: {
            enabled: false,
            apiKey: null,
        },
        Segment: {
            apiKey: 'G4i7Qbgws6CABI0XCfPTZyeqzaH84kP7',
            debug: false,
            loadOnInitialization: true,
        },
    },
};
