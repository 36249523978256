<div #wrapper [ngClass]="{'nav-sidebar-collapsed': !menuSideBar.shown, 'should-scroll': shouldScrollContainerScroll }"
     class="nav-sidebar border-right d-flex flex-column justify-content-between">
    <div #scrollContainer [ngStyle]="{'overflow-y': shouldScrollContainerScroll ? 'scroll' : null, 'overflow-x': shouldScrollContainerScroll ? 'visible' : null}"
         class="nav-sidebar-inner-scroll flex-grow-0">
        <nav #scrollContainerContent
            class="nav nav-top-level-items flex-column">
            <!--(resized)="onScrollContainerContentResized($event)"-->
            <li [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/']" class="nav-item"
                routerLinkActive="active">
                <a class="nav-link">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'home']"></fa-icon>
                    <span class="nav-link-text">{{ 'hub.menu.side-menu.home' | translate }}</span>
                </a>
                <ul class="nav nav-inner-level-items flex-column only-collapsed">
                    <li class="nav-item nav-inner-level-header d-none bold">
                        <a class="nav-link">{{ 'hub.menu.side-menu.home' | translate }}</a>
                    </li>
                </ul>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a [routerLink]="['/radar']" class="nav-link">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'satellite-dish']"></fa-icon>
                    <span class="nav-link-text">{{ 'hub.menu.side-menu.radar' | translate }}</span>
                </a>
                <ul class="nav nav-inner-level-items flex-column only-collapsed" routerLinkActive="active">
                    <li class="nav-item nav-inner-level-header d-none border-bottom bold">
                        <a [routerLink]="['/radar']" class="nav-link">
                            {{ 'hub.menu.side-menu.radar' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/radar', 'sales']" class="nav-link">
                            {{ 'hub.menu.side-menu.sales' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a [routerLink]="['/quotations']" class="nav-link">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'hand-holding-usd']"></fa-icon>
                    <span class="nav-link-text">{{ 'hub.menu.side-menu.quotations' | translate }}</span>
                </a>
                <ul class="nav nav-inner-level-items flex-column only-collapsed" routerLinkActive="active">
                    <li class="nav-item nav-inner-level-header d-none bold">
                        <a [routerLink]="['/quotations']" class="nav-link">
                            {{ 'hub.menu.side-menu.quotations' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a [routerLink]="['/shipments']" class="nav-link">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'box-alt']"></fa-icon>
                    <span class="nav-link-text">{{ 'hub.menu.side-menu.shipments' | translate }}</span>
                </a>
                <ul class="nav nav-inner-level-items flex-column only-collapsed" routerLinkActive="active">
                    <li class="nav-item nav-inner-level-header d-none">
                        <a [routerLink]="['/shipments']" class="nav-link">
                            {{ 'hub.menu.side-menu.shipments' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <li *ngIf="!isMagayaNetwork" class="nav-item" routerLinkActive="active">
                <a [routerLink]="['/invoices']" class="nav-link">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'file-invoice']"></fa-icon>
                    <span class="nav-link-text">{{ 'hub.menu.side-menu.invoices' | translate }}</span>
                </a>
                <ul class="nav nav-inner-level-items flex-column only-collapsed" routerLinkActive="active">
                    <li class="nav-item nav-inner-level-header d-none bold">
                        <a [routerLink]="['/invoices']" class="nav-link">
                            {{ 'hub.menu.side-menu.invoices' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a [routerLink]="['/rates', 'explorer']" class="nav-link">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'dollar-sign']"></fa-icon>
                    <span class="nav-link-text">{{ 'hub.menu.side-menu.rate-explorer' | translate }}</span>
                </a>
                <ul class="nav nav-inner-level-items flex-column  only-collapsed" routerLinkActive="active">
                    <li class="nav-item nav-inner-level-header d-none bold">
                        <a [routerLink]="['/rates', 'explorer']" class="nav-link">
                            {{ 'hub.menu.side-menu.rate-explorer' | translate }}
                        </a>
                    </li>
                </ul>
            </li>

            <ng-container *ngIf="user$ | async; let user">
                <li class="nav-item" routerLinkActive="active" *ngIf="!user.organization_features?.disable_global_chat?.value ?? false">
                    <a [routerLink]="['/conversations']" class="nav-link">
                        <fa-icon [fixedWidth]="true" [icon]="['fas', 'comments']"></fa-icon>
                        <span class="nav-link-text">{{ 'hub.menu.side-menu.conversations' | translate }}</span>
                    </a>
                    <ul class="nav nav-inner-level-items flex-column only-collapsed" routerLinkActive="active">
                        <li class="nav-item nav-inner-level-header d-none bold">
                            <a [routerLink]="['/conversations']" class="nav-link">
                                {{ 'hub.menu.side-menu.conversations' | translate }}
                            </a>
                        </li>
                    </ul>
                </li>
            </ng-container>

            <div class="border-bottom border-selected"></div>
            <li class="nav-item" routerLinkActive="active">
                <a [routerLink]="['/administration']" class="nav-link">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'door-open']"></fa-icon>
                    <span class="nav-link-text">{{ 'hub.menu.side-menu.admin-portal' | translate }}</span>
                </a>
                <ul class="nav nav-inner-level-items flex-column">
                    <li class="nav-item nav-inner-level-header d-none border-bottom bold">
                        <a [routerLink]="['/administration']" class="nav-link">
                            {{ 'hub.menu.side-menu.admin-portal' | translate }}
                        </a>
                    </li>
                    <li [routerLinkActiveOptions]="{exact:false}" class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/administration', 'portal-users']" class="nav-link">
                            {{ 'hub.menu.side-menu.users' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/administration', 'portal-teams']" class="nav-link">
                            {{ 'hub.menu.side-menu.customers' | translate }}
                        </a>
                    </li>
                    <li [routerLinkActiveOptions]="{exact:false}" class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/administration', 'sites']" class="nav-link">
                            {{ 'hub.menu.side-menu.sites' | translate }}
                        </a>
                    </li>
                    <li [routerLinkActiveOptions]="{exact:false}" class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/administration', 'margins']" class="nav-link">
                            {{ 'hub.menu.side-menu.margins' | translate }}
                        </a>
                    </li>
                    <li [routerLinkActiveOptions]="{exact:false}" class="nav-item" routerLinkActive="active" [pTooltip]="'hub.menu.side-menu.disclaimers' | translate">
                        <a [routerLink]="['/administration', 'disclaimers']" class="nav-link" >
                            {{ 'hub.menu.side-menu.disclaimers' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active" [pTooltip]="'hub.menu.side-menu.shipment-milestones' | translate">
                        <a
                            class="nav-link"
                            [routerLink]="['/administration', 'shipment-timeline']">
                            {{ 'hub.menu.side-menu.shipment-milestones' | translate }}
                        </a>
                    </li>
                    <li [routerLinkActiveOptions]="{exact:false}" class="nav-item" routerLinkActive="active" [pTooltip]="'hub.menu.side-menu.network-authorizations' | translate">
                        <a [routerLink]="['/administration', 'network-authorizations']" class="nav-link">
                            {{ 'hub.menu.side-menu.network-authorizations' | translate }}
                        </a>
                    </li>
                    <li *ngIf="isMagayaNetwork" [routerLinkActiveOptions]="{exact:false}" class="nav-item" routerLinkActive="active" [pTooltip]="'hub.menu.side-menu.magaya-event-mappings' | translate">
                        <a [routerLink]="['/administration', 'magaya-event-mappings']" class="nav-link">
                            {{ 'hub.menu.side-menu.magaya-event-mappings' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active" [pTooltip]="'hub.menu.side-menu.notifications' | translate">
                        <a [routerLink]="['/administration', 'notifications']" class="nav-link">
                            {{ 'hub.menu.side-menu.notifications' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a [routerLink]="['/schedules']" class="nav-link">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'clock']"></fa-icon>
                    <span class="nav-link-text">{{ 'hub.menu.side-menu.schedules' | translate }}</span>
                </a>
                <ul class="nav nav-inner-level-items flex-column">
                    <li class="nav-item nav-inner-level-header d-none border-bottom bold">
                        <a [routerLink]="['/schedules']" class="nav-link">
                            {{ 'hub.menu.side-menu.schedules' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/schedules', 'routingguide']" class="nav-link">
                            {{ 'hub.menu.side-menu.routing-guide' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/schedules', 'carriages']" class="nav-link">
                            {{ 'hub.menu.side-menu.carriages' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/schedules', 'closings']" class="nav-link disabled text-muted">
                            {{ 'hub.menu.side-menu.closings' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/schedules', 'providers']" class="nav-link">
                            {{ 'hub.menu.side-menu.providers' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/schedules', 'upload']" class="nav-link  disabled text-muted">
                            {{ 'hub.menu.side-menu.upload' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/schedules', 'templates']" class="nav-link  disabled text-muted">
                            {{ 'hub.menu.side-menu.templates' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/schedules', 'tasks']" class="nav-link">
                            {{ 'hub.menu.side-menu.tasks' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/schedules', 'reports']" class="nav-link disabled text-muted">
                            {{ 'hub.menu.side-menu.reports' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/schedules', 'settings']" class="nav-link disabled text-muted">
                            {{ 'hub.menu.side-menu.settings' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a [routerLink]="['/settings']" class="nav-link">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'cog']"></fa-icon>
                    <span class="nav-link-text">{{ 'hub.menu.side-menu.settings' | translate }}</span>
                </a>
                <ul class="nav nav-inner-level-items flex-column">
                    <li class="nav-item nav-inner-level-header d-none border-bottom bold" routerLinkActive="active">
                        <a [routerLink]="['/settings']" class="nav-link">
                            {{ 'hub.menu.side-menu.settings' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/settings', 'account']" class="nav-link">
                            {{ 'hub.menu.side-menu.account' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a [routerLink]="['/settings', 'users']" class="nav-link">
                            {{ 'hub.menu.side-menu.users' | translate }}
                        </a>
                    </li>
                    <li *ngIf="isMagayaNetwork" class="nav-item" routerLinkActive="active" [pTooltip]="'hub.menu.side-menu.payment-providers' | translate">
                        <a [routerLink]="['/settings', 'payment-providers']" class="nav-link">
                            {{ 'hub.menu.side-menu.payment-providers' | translate }}
                        </a>
                    </li>
                    <li class="nav-item text-muted d-none" routerLinkActive="active">
                        <a class="nav-link">
                            {{ 'hub.menu.side-menu.billing' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" href="https://docs.qwykportals.com" target="_blank">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'books']"></fa-icon>
                    <span class="nav-link-text">{{ 'hub.menu.side-menu.resources' | translate }}</span>
                </a>
                <ul class="nav nav-inner-level-items flex-column">
                    <li class="nav-item nav-inner-level-header d-none border-bottom bold" routerLinkActive="active">
                        <a class="nav-link" href="https://docs.qwykportals.com" target="_blank">
                            {{ 'hub.menu.side-menu.resources' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a (click)="onShowHelpCenter()" class="nav-link" href="javascript:void(0)">
                            {{ 'hub.menu.side-menu.help-center' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" href="https://docs.qwykportals.com/whats-new" target="_blank">
                            {{ 'hub.menu.side-menu.whats-new' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active" [pTooltip]="'hub.menu.side-menu.release-notes' | translate">
                        <a class="nav-link" href="https://docs.qwykportals.com/resources/release-notes" target="_blank">
                            {{ 'hub.menu.side-menu.release-notes' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <ng-container *ngIf="user$ | async; let user">
                <li *ngIf="user.is_master_admin" class="nav-item" routerLinkActive="active">
                    <a [routerLink]="['/console']" class="nav-link">
                        <fa-icon [fixedWidth]="true" [icon]="['fas', 'terminal']"></fa-icon>
                        <span class="nav-link-text">{{ 'hub.menu.side-menu.console' | translate }}</span>
                    </a>
                    <ul class="nav nav-inner-level-items flex-column">
                        <li class="nav-item nav-inner-level-header d-none border-bottom bold">
                            <a [routerLink]="['/console']" class="nav-link">
                                {{ 'hub.menu.side-menu.console' | translate }}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a [routerLink]="['/console/metrics']" class="nav-link">
                                {{ 'hub.menu.side-menu.metrics' | translate }}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a [routerLink]="['/console/organizations']" class="nav-link">
                                {{ 'hub.menu.side-menu.organizations' | translate }}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a [routerLink]="['/console', 'users']" class="nav-link">
                                {{ 'hub.menu.side-menu.users' | translate }}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a [routerLink]="['/console', 'api-clients']" class="nav-link">
                                {{ 'hub.menu.side-menu.api-clients' | translate }}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a [routerLink]="['/console', 'organization-connections']" class="nav-link">
                                {{ 'hub.menu.side-menu.integrations' | translate }}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a [routerLink]="['/console/magaya-network-configurations']" class="nav-link">
                                {{ 'hub.menu.side-menu.magaya-network' | translate }}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a [routerLink]="['/console', 'billing-rules']" class="nav-link">
                                {{ 'hub.menu.side-menu.billing-rules' | translate }}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a [routerLink]="['/console', 'invoices']" class="nav-link">
                                {{ 'hub.menu.side-menu.invoices' | translate }}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a [routerLink]="['/console', 'logs']" class="nav-link">
                                {{ 'hub.menu.side-menu.logs' | translate }}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a [routerLink]="['/console', 'maintenance-jobs']" class="nav-link">
                                {{ 'hub.menu.side-menu.maintenance' | translate }}
                            </a>
                        </li>
                    </ul>
                </li>
            </ng-container>
        </nav>
    </div>

    <div #staticContainer class="nav-sidebar-collapse-button overflow-hidden flex-shrink-0">
        <ul class="nav flex-column">
            <li [ngbTooltip]="!menuSideBar.shown ? ('common.expand' | translate) : null" class="nav-item border-top border-selected">
                <a (click)="menuSideBar.toggle()" class="nav-link">
                    <fa-icon [icon]="['fas', 'arrow-from-right']"></fa-icon>
                    <span class="nav-link-text">{{ 'common.collapse' | translate }}</span>
                </a>
            </li>
        </ul>
    </div>
</div>
