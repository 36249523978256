/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    switchMap,
    tap,
} from 'rxjs/operators';
import { QuotationsService } from '../../services/quotations.service';
import { UsersFacade } from '@qwyk/hub/settings/users';

@Component({
    selector: 'qwyk-quotation-list-search-form',
    templateUrl: './quotation-list-search-form.component.html',
    styleUrls: ['./quotation-list-search-form.component.scss'],
})
export class QuotationListSearchFormComponent implements OnInit {
    @Input() query: any;
    @Input() class: string;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();
    public form: FormGroup;
    public formExpanded = true;
    public showMoreOptions = false;

    public loadTypes = [
        {
            value: 'AIR:lcl',
            label: 'hub.shipment.search.filters.product.air:lcl',
        },
        {
            value: 'OCEAN:fcl',
            label: 'hub.shipment.search.filters.product.ocean:fcl',
        },
        {
            value: 'OCEAN:lcl',
            label: 'hub.shipment.search.filters.product.ocean:lcl',
        },
        {
            value: 'TRUCK:fcl',
            label: 'hub.shipment.search.filters.product.truck:fcl',
        },
        {
            value: 'TRUCK:lcl',
            label: 'hub.shipment.search.filters.product.truck:lcl',
        },
    ];

    public statuses = [
        { value: 'open', label: 'hub.quotations.status.open' },
        { value: 'draft', label: 'hub.quotations.status.draft' },
        { value: 'request', label: 'hub.quotations.status.request' },
        { value: 'booked', label: 'hub.quotations.status.booked' },
        { value: 'closed', label: 'hub.quotations.status.closed' },
    ];

    public locationTypes = [
        { value: 'port', label: 'common.port' },
        { value: 'place', label: 'common.door' },
    ];

    public numberOperators = [
        { value: '=', label: 'common.number-operators.equals' },
        { value: '>', label: 'common.number-operators.greater-than' },
        { value: '<', label: 'common.number-operators.less-than' },
    ];

    public dateOperators = [
        { value: '=', label: 'common.date-operators.is' },
        { value: '<>', label: 'common.date-operators.is-not' },
        { value: '<', label: 'common.date-operators.before' },
        { value: '>', label: 'common.date-operators.after' },
    ];

    public owners$: Observable<any[]>;
    public ownersLoading = false;
    public ownerInput$ = new Subject<string>();
    public hubUsers$ = this.hubUsers.allUsers$;

    constructor(
        fb: FormBuilder,
        private service: QuotationsService,
        private hubUsers: UsersFacade
    ) {
        this.form = QuotationListSearchFormComponent.createForm(fb);
    }

    private _disabled: boolean;

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set disabled(value: boolean) {
        this._disabled = value;

        if (value) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    private static createForm(fb: FormBuilder): FormGroup {
        return fb.group({
            friendly_id: [null],
            product: [null],
            owner_id: [null],
            origin_type: [null],
            origin_name: [null],
            destination_type: [null],
            destination_name: [null],
            offer_count: [null],
            offer_count_operator: [null],
            account_manager_ids: null,
            status: [null],
        });
    }

    ngOnInit(): void {
        if (this.query) {
            this.form.patchValue(this.query);
        }

        this.loadOwners();
        this.ownerInput$.next(' ');
        this.hubUsers.loadHubUsers({ pageSize: 99999 });
    }

    onSearch(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        const payload = { ...this.form.value };
        if (payload.owner_id) {
            payload.owner_id = payload.owner_id?.id;
        }

        this.search.emit(payload);
    }

    onReset(): void {
        this.form.reset();
        this.onSearch();
    }

    public searchOwners(e) {
        this.ownerInput$.next(e.query);
    }

    private loadOwners() {
        this.owners$ = this.ownerInput$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(() => (this.ownersLoading = true)),
            switchMap(term =>
                this.service.findUser(term).pipe(
                    catchError(() => of([])),
                    tap(() => (this.ownersLoading = false))
                )
            )
        );
    }
}
